import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import PageContent from "../components/PageContent";
import HeroPage from "../components/HeroPage";
import { getVideoTitle } from "../utils";

const IndexPage = props => {
    const { frontmatter, html } = props.data.file.childMarkdownRemark;
    const title = getVideoTitle(frontmatter.title);
    return (
        <Layout>
            <HeroPage title={"Videos"} subtitle={title}></HeroPage>
            <PageContent content={html}></PageContent>
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!) {
        file(sourceInstanceName: { eq: "videos" }, name: { eq: $slug }) {
            name
            childMarkdownRemark {
                frontmatter {
                    title
                }
                html
            }
        }
    }
`;

export default IndexPage;
