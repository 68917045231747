import React from "react";

import styled from "styled-components";
import { md } from "../../constants/MediaQueries";
import { Markdown, Container } from "../global";

const Outer = styled.div`
    padding: 40px 0;

    ${md} {
        padding: 80px 0;
    }
`;

const Inner = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;

const PageContent = (props: { content: string }) => {
    return (
        <Outer>
            <Container>
                <Inner>
                    <Markdown input={props.content}></Markdown>
                </Inner>
            </Container>
        </Outer>
    );
};

export default PageContent;
